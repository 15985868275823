import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Button from '../components/component.button';

const CTABanner = ({ title='Find the right boat for you', linkLabel='Get started', linkUrl={ url: '/downloads/' }, image, description=false }) => {
    const defaultBannerImage = useStaticQuery(graphql`
        query {
            wintechFile: file(relativePath: { eq: "default-cta-banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 735) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
            kingFile: file(relativePath: { eq: "default-cta-banner-king.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 735) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    let defaultImageSrc = defaultBannerImage.wintechFile;
    if(process.env.GATSBY_THEME == "King") { 
        defaultImageSrc = defaultBannerImage.kingFile;
        title = 'Find the right boat for you';
        linkLabel = 'Book a demo';
        linkUrl={ url: '/demo/' };
    }
    
    const bannerImage = image ? image.imageFile.childImageSharp.fluid : defaultImageSrc.childImageSharp.fluid;
    const bannerAlt = image ? image.altText : 'WinTech rowing boat in competition';
    
    return (
        <div className="container">
            <div className="c-banner">
                <Img className="c-banner__image" fluid={bannerImage} alt={bannerAlt} />
                <div className="c-banner__details">
                    <span className="c-banner__title">{title}</span>
                    {description && (
                        <p className="c-banner__description">{description}</p>
                    )}
                    <Button link className="c-banner__button" to={linkUrl.url} text={linkLabel} />
                </div>
            </div>
        </div>
    );
};

export default CTABanner;
